import {PlayerAnalytics} from '../api/models/player-analytics-model';
import {mapActions, mapState} from 'vuex';

export default {
    props: {
        analyticsOptions: null,
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            geo: state => state.geo,
            channelSettings: state => state.channel.settings,
        })
    },
    data: () => ({player: null}),
    created() {
    },
    methods: {
        ...mapActions(['GET_GEO_DATA']),
        async setupAnalytics() {
            if (!this.analyticsOptions || !this.player) {
                return;
            }

            const device_type = (window.innerWidth <= 800 && window.innerHeight <= 600) ? 'mobile' : 'desktop';
            const platform = navigator.platform;
            const browser = navigator.userAgent;

            let countryCode = null;
            let ip = null;
            let email = null;
            if (this.user) {
                countryCode = this.user.country_code;
                ip = this.user.ip;
                email = this.user.email;
            } else {
                if (!this.geo.ip) {
                    await this.GET_GEO_DATA()
                }

                countryCode = this.geo.country_code;
                ip = this.geo.ip;
                email = this.channelSettings?.owner?.email;
            }

            //get the key from settings or set default key
            const analyticsKey = this.channelSettings && this.channelSettings['yare-analytics'] ? this.channelSettings['yare-analytics']['yare-analytics-key'] ?? '7cKQXOvq' : '7cKQXOvq';

            this.analytics = new PlayerAnalytics({
                player: this.player,
                profile: analyticsKey,
                tag: this.analyticsOptions.tag,
                title: this.analyticsOptions.videoTitle,
                email: email,
                stream_type: String(this.analyticsOptions.streamType).toUpperCase(),
                unique_id: this.analyticsOptions.uniqueId,
                country_code: countryCode,
                ip: ip,
                device_type: device_type,
                platform: platform,
                browser: browser
            });
        }
    }
}
