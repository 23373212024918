class Logger {
    enabled = true
    level = null
    static LEVEL_DUMMY = 0
    static LEVEL_INFO = 1
    static LEVEL_LOG = 2

    constructor(level = Logger.LEVEL_LOG) {
        this.level = level
    }


    dummy(...args) {
        this._log(Logger.LEVEL_DUMMY, ...args)
    }

    info(...args) {
        this._log(Logger.LEVEL_INFO, ...args)
    }

    log(...args) {
        this._log(Logger.LEVEL_LOG, ...args)
    }

    disableLogs() {
        this.enabled = false
    }

    setLogLevel(level) {
        this.level = level
    }

    _log(level, ...args) {
        if (!this._canLog(level)) {
            return
        }

        console.log(...args)
    }

    _canLog(level) {
        return this.enabled && this.level <= level
    }
}

class CookieHelper {
    static getCookie(cookieName) {
        const name = cookieName + '=';
        //const decodedCookie = decodeURIComponent(document.cookie);
        const decodedCookie = document.cookie;
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();

            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return undefined;
    }

    static setCookie(name, value, expireDays) {
        const d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    }
}

export class PlayerAnalytics {
    ENDPOINT_EVENT = 'session/event'
    ENDPOINT_SESSION = 'session'
    ENDPOINT_VIEWING_TIME = 'session/viewing_time'

    apiEndpoint = 'https://edk9uh5ed3.execute-api.us-west-2.amazonaws.com/staging/';
    analyticData = {}

    constructor({player, profile, tag, title, email, stream_type, unique_id, country_code, ip, device_type, platform, browser}) {
        this.logger = new Logger(Logger.LEVEL_INFO);

        this.analyticData.profile = profile;
        this.analyticData.tag = tag;
        this.analyticData.title = title;
        this.analyticData.email = email;
        this.analyticData.stream_type = stream_type;
        this.analyticData.unique_id = unique_id;
        this.analyticData.country_code = country_code;
        this.analyticData.ip = ip;
        this.analyticData.device_type = device_type;
        this.analyticData.platform = platform;
        this.analyticData.browser = browser;
        this.analyticData.bitrate = 0;
        this.analyticData.resolution = '';
        this.analyticData.session = this.getSession();
        this.analyticData.totalTimeWatched = 0;
        this.analyticData.previousPosition = 0;
        this.analyticData.view_uuid = this.generateUUID();

        this.player = player;

        this.setTriggerEvents()
        this.logger.log('data = ', ip, country_code);
    }

    constantlySentData(){
        return {
            session: this.analyticData.session,
            profile: this.analyticData.profile,
            view_uuid: this.analyticData.view_uuid,
            unique_id: this.analyticData.unique_id,
            tag: this.analyticData.tag,
            email: this.analyticData.email,
            stream_type: this.analyticData.stream_type,
        }
    }

    sendStreamingTime() {
        if (this.analyticData.totalTimeWatched <= 0) {
            return;
        }

        if (this.analyticData.totalTimeWatched < 120) {
            this.logger.info('SENDING Viewing Time: ' + this.analyticData.totalTimeWatched)
            this.sendViewingTime();
        }

        this.analyticData.totalTimeWatched = 0;
    }

    sendViewingTime() {
        this.sendAnalytics(this.ENDPOINT_VIEWING_TIME, {
            ...this.constantlySentData(),
            viewing_time: this.analyticData.totalTimeWatched,
            bitrate: this.analyticData.bitrate,
            resolution: this.analyticData.resolution,
            title: this.analyticData.title,
        });
    }

    sendSessionEvent(event, value = {}) {
        this.sendAnalytics(this.ENDPOINT_EVENT, {
            ...this.constantlySentData(),
            event: event,
            value: JSON.stringify(value)
        })
    }

    sendAnalytics(endPoint, values) {
        const image = new Image(1, 1);

        let params = ''
        if (typeof values === 'object') {
            params = Object.getOwnPropertyNames(values)
                .map(prop => `${prop}=${values[prop]}`.replace(';', ''))
                .join('&')
        } else {
            params = values.replace(';', '')
        }

        image.src = encodeURI(this.apiEndpoint + endPoint + '/?' + params);
    }

    getSession(name = 'jwplayer_session_id') {
        const cookieValue = CookieHelper.getCookie(name)

        if (cookieValue !== undefined) {
            this.logger.info('Detected existing session')
            return cookieValue
        }

        const sessionId = this.generateUUID();
        CookieHelper.setCookie(name, sessionId, 1);

        this.logger.info('Creating new session')
        this.sendNewSessionToAnalytics(sessionId);

        return sessionId;
    }

    sendNewSessionToAnalytics(sessionId) {
        this.sendAnalytics(this.ENDPOINT_SESSION, {
            session: sessionId,
            profile: this.analyticData.profile,
            country_code: this.analyticData.country_code,
            ip: this.analyticData.ip,
            device_type: this.analyticData.device_type,
            platform: this.analyticData.platform,
            browser: this.analyticData.browser,
        });
    }

    generateUUID() {
        const timestamp = new Date().valueOf();
        return ('xxxxxxxx-xxxx-xxxx-' + timestamp).replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    setTriggerEvents() {
        const handlers = {
            ready: (e) => {
                this.sendSessionEvent('ready', e);
                this.logger.info('ready on: ' + e['setupTime'] + 'ms');
                this.logger.info('ready viewble: ' + e['viewable']);
                this.logger.info(this.player.getPosition());
            },
            firstFrame: (e) => {
                const levels = this.player.getQualityLevels();
                const firstFrameObj = {...e, ...levels[this.player.getCurrentQuality()]}
                this.analyticData.totalTimeWatched = 0.01;
                this.sendStreamingTime();
                this.sendSessionEvent('firstFrame', firstFrameObj);
                this.logger.info('firstFrame');
            },
            seek: (e) => {
                const {offset} = e;
                this.analyticData.previousPosition = offset;
            },
            time: (e) => {
                const {position} = e;
                this.analyticData.totalTimeWatched += (position - this.analyticData.previousPosition);
                this.analyticData.previousPosition = position;
                this.logger.dummy('totalTimeWatched', this.analyticData.totalTimeWatched);
            },
            complete: (e) => {
                this.sendSessionEvent('complete', e);
                this.logger.info('complete', e);
            },
            error: (e) => {
                this.sendSessionEvent('error', e.code);
                this.logger.log('error', e);
            },
            playAttemptFailed: (e) => {
                this.sendSessionEvent('playAttemptFailed', e);
                this.logger.info('playAttemptFailed');
            },
            visualQuality: (e) => {
                const {level} = e;

                if (typeof level['bitrate'] == 'number') {
                    this.bitrate = level['bitrate'];
                }

                this.resolution = level['height'];
                this.logger.info('changeVisualQuality', this.bitrate, this.resolution)
            }
        };

        setInterval(this.sendStreamingTime.bind(this), 60000)
        this.setPlayerEventHandlers(handlers)
    }

    setPlayerEventHandlers(handlers) {
        Object.getOwnPropertyNames(handlers).forEach((eventName) => {
            this.player.on(eventName, handlers[eventName])
        })
    }
}
