<template>
  <div @click.stop.prevent="onPlayerClick" class="position-relative">
    <div v-if="error" class="error-message" @click.stop.prevent="onErrorClick">
      {{ $t('player-error') }}
    </div>
    <div id="videoPlayer"></div>
    <div v-if="preRollPlaying" class="disabled-player-click"></div>
  </div>
</template>

<script>
import { EventBus } from '../../utils/event-bus';
import PlayerAnalytics from '../../mixins/PlayerAnalytics';
import { PLAYER_ERROR } from '@/const';
import { mapActions } from 'vuex';

const videoOptions = {
  file: '',
  logo: {},
  width: '100%',
  aspectratio: '16:9',
  hlshtml: true,
  primary: 'html5',
  autostart: false,
  repeat: false,
  abouttext: 'Rudy',
  aboutlink: '/',
  playbackRateControls: true,
  playbackRates: [0.25, 0.75, 1, 1.25, 2, 3],
  cast: {},
};

export default {
  name: 'VideoPlayer',
  mixins: [PlayerAnalytics],
  props: {
    options: {},
  },
  data() {
    return {
      player: null,
      currentRefreshIndex: 0,
      refreshInterval: null,
      error: false,
      preRollPlaying: false,
    };
  },
  mounted() {
    const script = document.createElement('script');
    script.onload = () => {
      this.setupPlayer();
    };
    script.src = 'https://cdn.jwplayer.com/libraries/CjZtBP3i.js';
    document.head.appendChild(script);
  },
  methods: {
    ...mapActions(['POST_SEND_CLIP']),
    playPreRoll() {
      this.preRollPlaying = true;
      let opt = Object.assign({}, videoOptions, this.options);
      opt.file = this.options.preRoll;
      this.player.setup(opt);
      this.player.once('complete', this.playMainVideo);
      this.player.on('error', this.playMainVideo);
    },

    playMainVideo() {
      this.preRollPlaying = false;
      this.player.off('error', this.playMainVideo);
      if (!this.options.preRoll) {
        let opt = Object.assign({}, videoOptions, this.options);
        this.player.setup(opt);
      } else {
        this.player.once('meta', () => {
          this.player.play();
        });
        this.player.load({
          file: this.options.file,
        });
      }
      this.player.on('error', this.onErrorHandler);
      this.setupAnalytics();
      this.options.preRoll = null;
    },
    setupPlayer() {
      this.player = window.jwplayer('videoPlayer');
      this.options.preRoll ? this.playPreRoll() : this.playMainVideo();
      this.busConfig();
    },
    //@author Lucas Mello <lucas.mello@icaromediagroup.com>
    // An event bus is used to communicate between the video player component and the clipComponent.vue
    busConfig() {
      this.player.on('time', (e) => {
        const playerTime = {
          currentTime: e.currentTime,
          duration: e.duration 
        }
        EventBus.$emit('current-time-updated', playerTime);
      });
      EventBus.$on('pause', () => {
        this.player.pause();
      });
      EventBus.$on('play', ({ startSeconds, clipDuration }) => {
        this.player.pause();
        this.player.seek(startSeconds);
        this.player.play();
        setTimeout(() => {
          this.player.pause();
        }, clipDuration * 1000);
      });
      EventBus.$on('rewind', (value) => {
        const time = this.player.getPosition();
        this.player.seek(time - value);
      });
      EventBus.$on('cut', (value) => {
        const config = this.player.getConfig();
        function formatTime(timeInSeconds) {
          const hours = Math.floor(timeInSeconds / 3600);
          const minutes = Math.floor((timeInSeconds % 3600) / 60);
          const seconds = Math.floor(timeInSeconds % 60);
          const formattedHours = hours.toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          const formattedSeconds = seconds.toString().padStart(2, '0');
          return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
        const currentTime = this.player.getCurrentTime();
        let channel_host;
        if (process?.env?.VUE_APP_CHANNEL_CONFIG_NAME) {
          channel_host = process.env.VUE_APP_CHANNEL_CONFIG_NAME;
        } else {
          channel_host = document.location.host.split('.')[0];
        }

        let payload = {
          event_id: this.$route.params.id,
          out_video_name: value.inputText,
          start_time: formatTime(currentTime),
          clip_duration: formatTime(value.selectedDuration),
          tags: [ JSON.stringify(value.tag) ],
          platform: channel_host,
        };
        this.POST_SEND_CLIP(payload);
      });
    },
    destroyPlayer() {
      this.player.remove();
    },
    onPlayerClick(e) {
      this.$emit('player-click', e);
    },
    autoReloadPlayer() {
      clearInterval(this.refreshInterval);
      this.destroyPlayer();
      this.error = false;
      this.$nextTick(this.setupPlayer);
    },
    onErrorHandler(e) {
      this.error = true;
      if (this.currentRefreshIndex > PLAYER_ERROR.MAX_REFRESH_COUNT) return;
      const interval = this.currentRefreshIndex * PLAYER_ERROR.REFRESH_DURATION;
      this.currentRefreshIndex += 1;
      this.refreshInterval = setTimeout(this.autoReloadPlayer, interval);
    },
    onErrorClick() {
      this.autoReloadPlayer();
    },
  },
  beforeDestroy() {
    EventBus.$off('pause')    
    EventBus.$off('rewind')    
    EventBus.$off('cut')     
    EventBus.$off('play')     
    EventBus.$off('time')
    clearInterval(this.refreshInterval);
  },
};
</script>

<style lang="scss">
.jw-breakpoint-7:not(.jw-flag-audio-player)
  .jw-controlbar
  .jw-button-container
  .jw-icon-inline:not(.jw-text-live) {
  min-width: fit-content;
}

#videoPlayer .jw-time-tip .jw-text {
  white-space: nowrap;
}
.disabled-player-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.error-message {
  position: absolute;
  z-index: 2;
  background: linear-gradient(to top, #1f1f1f 10%, #333333);
  color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
